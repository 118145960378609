<template>
  <div class="login-modal  modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content themeBlue-before">
        <div class="modal-header justify-content-center mx-3">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title fontsize14 font-weight-bold text-color-444"
              id="loginModalLabel">ورود به وبسایت</h5>
        </div>

        <div class="modal-body  px-sm-5 px-3 pb-sm-5 pb-3">
          <div class="d-flex justify-content-center my-3 px-2">
            <svg class="flex-shrink-0" width="16" height="16" viewBox="0 0 24 24"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M18.1666 14.3797C17.4949 14.2352 16.9635 14.5471 16.493 14.8195C16.0111 15.1001 15.0949 15.8434 14.5697 15.6532C11.8808 14.5461 9.35189 12.1926 8.25714 9.49291C8.0642 8.95651 8.804 8.03445 9.08259 7.54691C9.35292 7.07496 9.65833 6.53856 9.51904 5.86183C9.39316 5.2537 7.76497 3.18192 7.18923 2.61538C6.80952 2.24115 6.42053 2.03532 6.02123 2.00205C4.51995 1.9376 2.84327 3.94078 2.54921 4.42C1.8125 5.44186 1.81663 6.80157 2.56159 8.45026C4.35693 12.8787 11.1472 19.5618 15.5922 21.4246C16.4125 21.8082 17.1626 22.0005 17.8364 22.0005C18.4957 22.0005 19.0828 21.8165 19.5874 21.4517C19.9681 21.2323 22.0534 19.4724 21.9987 17.9308C21.9657 17.5378 21.7604 17.1449 21.391 16.7644C20.8286 16.1833 18.7702 14.5066 18.1666 14.3797Z"
                  fill="#a5a5a5"/>
            </svg>
            <p class="fontsize11 mr-2 text-center">
              <template v-if="mode === 'get-mobile'">
                لطفا شماره تماس خود را با دقت وارد کنیدتا
                کد ارسالی را دریافت کنید.
              </template>
              <template v-else>
                لطفا کد 4 رفمی دریافت شده را وارد نمایید
              </template>
            </p>
          </div>
          <transition name="fade">
            <ValidationObserver v-if="mode === 'get-mobile'" v-slot="{ handleSubmit }">
              <form @submit.prevent="(e) => handleSubmit(() => submitLogin(e))"
                    class="d-flex flex-column mx-sm-5 mx-0 fontsize12">
                <ValidationProvider mode="passive" class="mb-3 text-right" rules="required|phone_number"
                                    v-slot="v">
                  <input v-number type="text" v-model="phoneNumber"
                         name="mobile" maxlength="11"
                         class="shadow-input p-2 text-center w-100 mb-1" placeholder="شماره موبایل...">
                  <span class="text-right text-danger mt-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <input :disabled="sendingRequest" type="submit" class="p-2 weight-bold text-color-themeBlue"
                       value="ارسال کد ورود">
              </form>
            </ValidationObserver>
            <ValidationObserver v-else v-slot="{ handleSubmit }" class="verify-container ">
              <form ref="codeForm" class="submit-code-form" @submit.prevent="(e) => handleSubmit(() => submitCode())">
                <div>
                  <input ref="firstCodeInput" @keyup="goToNextInput" type="tel" class="code-input text-center mx-2" min="1" max="9"
                         v-model="codes[0]" data-required pattern="[0-9]{1}" maxlength="1" :disabled="sendingRequest" />
                  <input @keyup="goToNextInput" type="tel" class="code-input text-center mx-2" min="1" max="9" pattern="[0-9]{1}"
                         v-model="codes[1]" data-required maxlength="1" :disabled="sendingRequest" />
                  <input @keyup="goToNextInput" type="tel" class="code-input text-center mx-2" min="1" max="9" pattern="[0-9]{1}"
                         v-model="codes[2]" data-required maxlength="1" :disabled="sendingRequest" />
                  <input @keyup="(e) => {if(!goToNextInput(e)) return false; console.log('okokok');submitCode()}" type="tel" class="code-input text-center mx-2" min="1" max="9" pattern="[0-9]{1}"
                         v-model="codes[3]" data-required maxlength="1" :disabled="sendingRequest" />
                  <input type="hidden" name="verification" :value="codes.join('')">
                  <input type="hidden" name="mobile" :value="phoneNumber">
                </div>
                <div style="display: flex;justify-content: center;gap: 8px;">
                  <input :disabled="sendingRequest || !(codes[0] && codes[3])"
                         type="submit" class="btn btn-primary btn-theme"
                         style="font-size: 16px;height: auto;padding: 7px;"
                         value="ورود">
                  <button type="button" class="btn btn-secondary" style="border-radius: 10px"
                          @click="() => {phoneNumber = ''; mode = 'get-mobile';codes= Array(4).fill('')}">بازگشت</button>
                </div>
              </form>
            </ValidationObserver>
          </transition>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  components: {ValidationProvider, ValidationObserver},
  data() {
    return {
      mode: 'get-mobile', // get-code
      phoneNumber: '',
      codes: Array(4).fill(''),
      sendingRequest: false,
    }
  },
  methods: {
    submitLogin(e) {
      this.sendingRequest = true;
      let formData = new FormData(e.target)
      this.$axios.post("/api/login", formData).then((response) => {
        this.$root.success_notification(response.data.message);
        this.mode = 'send-code';
        setTimeout(() => {
          this.$refs.firstCodeInput.focus();
        })
      })
          .catch((error) => {
            console.log(error)
            //handle error
            this.$root.error_notification(error)
          }).finally(() => {
        this.sendingRequest = false
      });
    },
    submitCode() {
      this.sendingRequest = true;
      let formData = new FormData(this.$refs.codeForm)
      this.$axios.post("/api/verification", formData).then((response) => {
        this.$root.success_notification(response.data.message);
        let token = response.data.data.access_token;
        this.$axios.defaults.headers.common[
            "Authorization"
            ] = 'Bearer ' + token;
        this.$root.setCookie(
            `userAuthorization = Bearer ${token}`
        , 90);
        let data = response.data.data;
        this.$store.commit('header/setUser', data.user);
        this.$store.commit('header/setCart' , data['cart-items'].data.cartItems);
        this.$('#loginModal').modal('hide')
        if (this.$store.state.pendingCartToAdd) {
          this.$axios.post(`/api/cart/add/${this.$store.state.pendingCartToAdd.id}`, {
              number: this.$store.state.pendingCartToAdd.number,
              unit: this.$store.state.pendingCartToAdd.unit
            })
            .then((response) => {
              this.$root.success_notification(response.data.message)
              this.$store.commit('header/pushCart', response.data.data)
              this.$router.push('/cart')
            })
            .catch((error) => {
              console.log(error);
              this.$root.error_notification(error)
            })
            .finally(() => {
              this.$store.state.pendingCartToAdd = null
            })
        }
      })
          .catch((error) => {
            console.log(error)
            //handle error
            this.$root.error_notification(error)
          }).finally(() => {
        this.sendingRequest = false
      });
    },
    goToNextInput(e) {
      var key = e.which,
      t = window.$(e.target),
      sib = t.next("input"),
      prevSib = t.prev("input");
      if (key !== 8) {
        if (key === 229) {
          console.log("SS");
        } else {
          if (key !== 9 && (key < 48 || key > 57) && (key <= 95 || key >= 106)) {
            e.preventDefault();
            return false;
          }

          if (key === 9) {
            return true;
          }
        }
        if (!sib || !sib.length) {
          this.submitCode()
        }
        sib.select().focus();
      }
      if (key === 8) {
        prevSib.select().focus();
      }

      return true;
    },

  },
  name: "LoginModal"
}
</script>

<style scoped>

.submit-code-form {
  display: flex;
  flex-direction: column;
  gap: 33px;
}
.submit-code-form > div {
  direction: ltr;
  justify-content: center;
  display: flex;
}
.submit-code-form > div input {
  width: 50px;
  height: 50px;
  font-size: 18px;
}
.close {
  position: absolute;
  left: 25px;
}
</style>
