<template>
  <form
    @submit.prevent="proSearch"
    autocomplete="off"
    class="top-header-search border position-relative  px-2 py-1 fontsize12"
    :style="
      showResults
        ? 'border-radius: 9px 9px 0 0;box-shadow: 0 0 4px 0px #7570b32b;'
        : ''
    "
  >
    <input
      @click="insideClicked"
      ref="search"
      @keyup="handleInput"
      v-model="q"
      name="q"
      class="search-inside-click light-color"
      type="search"
      placeholder="جستجو کنید..."
    />
    <hr />
    <!-- <select
      v-model="c"
      @change="sendRequest"
      class="search-inside-click light-color"
      name="c"
      style="background: transparent ; color: #666"
    >
      <option class="search-inside-click" value="0" selected disabled
        >جستجوی پیشرفته</option
      >
      <template v-for="(category, index) in $store.state.header.categories">
        <option
          class="search-inside-click"
          v-if="category"
          :key="index"
          :value="category.id"
          >{{ category.name }}</option
        >
      </template>
    </select> -->
    <hr style="width: 1px;" />
    <button type="submit" class="search-button pr-2">
      <SearchSvg />
    </button>
    <transition name="fade">
      <div v-if="showResults" class="search-results position-absolute">
        <div
          :class="{ 'pt-3': products.length === 0 || sendingRequest }"
          class="text-center d-inline-block w-100"
        >
          <div
            v-if="sendingRequest"
            style="color: #0e3a62"
            class="spinner-border mb-3"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else-if="products.length === 0">
            <a class="search font-weight-bold fontsize15" v-if="q.length < 2"
              >لطفا تایپ کنید</a
            >
            <a class="search" v-else>محصولی یافت نشد</a>
          </div>
          <router-link
            v-else
            :to="'/product/' + product.id + '/' + product.slug"
            class="search-result-item w-100 a-n"
            v-for="product in products"
            :key="product.id"
          >
            <img
              class="search-item-image"
              :src="product.image"
              :alt="product.title"
            />
            <div class="search-title">
              <button class="m-0 text-right search-user-fullname">{{
                product.title
              }}</button>
              <router-link
                tag="span"
                :to="
                  '/products/category/' +
                    product.category.id +
                    '/' +
                    product.category.slug
                "
                style="position:unset;"
                class="edu-topic bg-color-fa3466 text-white fontsize10 font-weight-bold rounded-pill"
                >{{ product.category.name }}</router-link
              >
            </div>
            <div class="d-flex justify-content-end" style="flex: 2 0">
              <router-link
                :to="'/product/' + product.id + '/' + product.slug"
                tag="a"
                style="color: white"
                class="btn btn-primary btn-theme search-show-btn"
                >مشاهده</router-link
              >
            </div>
          </router-link>
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
import debounce from "lodash/debounce";
import SearchSvg from "../svgs/SearchSvg";

export default {
  name: "Search",
  components: { SearchSvg },
  data() {
    return {
      q: "",
      c: "0",
      showResults: false,
      sendingRequest: false,
      products: [],
      searchFunction: null,
    };
  },
  mounted() {
    this.$refs.search.addEventListener("search", this.handleInput);
    this.searchFunction = debounce(this.sendRequest, 400);
  },
  methods: {
    handleInput(e) {
      if (e.type === "search") {
        this.products = [];
        this.sendingRequest = false;
        return;
      }
      if (this.q !== this.oldQ && this.q !== "") {
        this.oldQ = this.q;
        if (this.q.length > 1) {
          this.searchFunction();
        }
      }
    },
    sendRequest() {
      let q = this.q;
      let c = this.c;
      this.sendingRequest = true;

      this.$axios
        .get("/api/search", {
          params: {
            title: q,
            c: c,
          },
        })
        .then((response) => {
          this.products = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => {
          this.sendingRequest = false;
        });
    },
    insideClicked() {
      this.showResults = true;
      window.addEventListener("click", this.outsideClicked);
    },
    outsideClicked(e) {
      if (!e.target.className.includes("search-inside-click")) {
        this.showResults = false;
        window.removeEventListener("click", this.outsideClicked);
      }
    },
    proSearch() {
      this.showResults = false;
      if (this.c != 0) {
        this.$router.push("/products/category/" + this.c + "?title=" + this.q);
      } else {
        this.$router.push("/products?title=" + this.q);
      }
    },
  },
};
</script>

<style scoped>
/* width */
.search-results::-webkit-scrollbar {
  width: 17px;
}

/* Track */
.search-results::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.search-results::-webkit-scrollbar-thumb {
  background: var(--color-themeBlue);
  border-radius: 10px;
}

.search-results {
  min-height: 60px;
  max-height: 60vh;
  background: white;
  border-radius: 0 0 10px 10px;
  /*box-shadow: 0px 0px 2px 0px #e1e1e1;*/
  transition: 250ms all;
  top: 103%;
  z-index: 3;
  right: 0;
  width: 100%;
  box-shadow: 0 0 4px 0px #7570b32b;
  padding: 5px 0px;
  text-align: right;
  overflow-y: auto;
}

.search-result-item {
  display: flex;
  gap: 20px;
  padding: 7px 13px;
  align-items: center;
}

.search-item-image {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.search-show-btn {
  font-size: 0.8rem;
  height: max-content;
}

.search-title {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.edu-topic {
  padding: 2px 8px;
  margin-top: 4px;
}
</style>
