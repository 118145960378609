<template>
  <header>
    <div class="headerContinaer">
      <div>
        <router-link id="logoContainer" to="/" class="logo">
          <img src="../../../assets/images/albaLogo.png" alt="آلبا مارکت" />
        </router-link>
      </div>

      <section
        id="top-header"
        class="top-header container d-flex justify-content-between flex-wrap pt-3 pb-3"
      >
        <div class="d-flex justify-content-start top-header-R">
          <!--resp menu button -->
          <button
            type="button"
            id="all-categories"
            class="resp-menu-btn all-categories  d-xl-none py-2 px-2 ml-3"
            @click="allCategoriesClose()"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.28308 19L20 19M20 5L12.9719 5"
                stroke="#363853"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M19.9996 12L4 12"
                stroke="#0095FF"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </button>

          <Search></Search>

          <!--        FOR MOBILE -->
          <div class="d-flex d-xl-none float-left mobile-buttons">
            <button
              type="button"
              class="resp-search-btn d-xl-none rounded-circle"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <svg
                style="transform: translate(0, 1px)"
                class=" mt-n1 "
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11.7666"
                  cy="11.7666"
                  r="8.98856"
                  stroke="#444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.0183 18.4851L21.5423 22"
                  stroke="#444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <MobileSearch></MobileSearch>
            <Dropdown
              v-if="this.$store.state.header.user"
              dropdown-styles="transform: translateX(-50%)"
              button-container-styles="display:inline"
              class="d-inline"
            >
              <template v-slot:button>
                <button
                  id="account-btn"
                  class="account-button bg-color-ea rounded-circle"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                      fill="#444"
                    />
                  </svg>
                </button>
              </template>
              <template v-slot:dropdown>
                <div class="profile-container">
                  <ul>
                    <router-link
                      tag="li"
                      to="/profile"
                      style="border-radius: 10px 10px 0 0"
                    >
                      <UserIcon fill="#666" style="height: 12px"></UserIcon>
                      صفحه پروفایل
                    </router-link>
                    <li
                      v-b-modal.logout-modal
                      style="padding-right:12px;border-radius: 0 0 10px 10px;border-bottom: unset"
                    >
                      <LogoutIcon fill="#666" style="height: 12px"></LogoutIcon>
                      خروج
                    </li>
                  </ul>
                </div>
              </template>
            </Dropdown>
            <button
              v-else
              id="account-btn"
              class="account-button bg-color-ea rounded-circle"
              @click="profileClicked"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                  fill="#444"
                />
              </svg>
            </button>
            <div
              v-if="$store.getters['header/isAuth']"
              class="fontsize13 text-color-444 position-relative"
            >
              <button
                id="cart-btn-mobile"
                class="header-cart-button  bg-color-ea rounded-circle"
                @click="cartModalOpenMobile()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                    fill="#F87C56"
                  />
                </svg>
                <span class="bg-color-themeBlue text-white rounded-circle">{{
                  $store.state.header.cart.length
                }}</span>
              </button>
              <CartModal :closeCartModal="cartModalOpenMobile"></CartModal>
            </div>
          </div>
        </div>

        <!--resp menu -->
        <MobileMenu :allCategoriesClose="allCategoriesClose"></MobileMenu>
        <!--resp menu button-->
        <div
          class="top-header-L d-none d-xl-flex justify-content-between my-auto"
        >
          <!-- resp search Button trigger modal  -->
          <button
            type="button"
            class="resp-search-btn d-xl-none rounded-circle"
            data-toggle="modal"
            data-target="#searchModal"
          >
            <svg
              class=" mt-n1 "
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.7666"
                cy="11.7666"
                r="8.98856"
                stroke="#444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.0183 18.4851L21.5423 22"
                stroke="#444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <div
            class="fontsize15 text-color-444 mx-3"
            :class="{ 'font-weight-bold': !$store.getters['header/isAuth'] }"
          >
            <label for="account-btn"
              >{{
                userInfo
                  ? userInfo.first_name
                    ? userInfo.first_name +
                      " " +
                      userInfo.last_name +
                      " عزیز خوش آمدید"
                    : "خوش آمدید"
                  : "ورود"
              }}
              <svg
                width="11"
                height="11"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 19L8.5 12L15.5 5"
                  stroke="#a5a5a5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </label>
            <Dropdown
              v-if="this.$store.state.header.user"
              dropdown-styles="transform: translateX(-50%)"
              button-container-styles="display:inline"
              class="d-inline"
            >
              <template v-slot:button>
                <button
                  id="account-btn"
                  class="account-button bg-color-ea rounded-circle"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                      fill="#444"
                    />
                  </svg>
                </button>
              </template>
              <template v-slot:dropdown>
                <div class="profile-container">
                  <ul>
                    <router-link
                      tag="li"
                      to="/profile"
                      style="border-radius: 10px 10px 0 0"
                    >
                      <UserIcon fill="#666" style="height: 12px"></UserIcon>
                      صفحه پروفایل
                    </router-link>
                    <li
                      v-b-modal.logout-modal
                      style="padding-right:12px;border-radius: 0 0 10px 10px;border-bottom: unset"
                    >
                      <LogoutIcon fill="#666" style="height: 12px"></LogoutIcon>
                      خروج
                    </li>
                  </ul>
                </div>
              </template>
            </Dropdown>
            <button
              v-else
              id="account-btn"
              class="account-button bg-color-ea rounded-circle"
              @click="profileClicked"
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                  fill="#444"
                />
              </svg>
            </button>
            <!-- resp login Modal -->
          </div>
          <div
            v-if="$store.getters['header/isAuth']"
            class="fontsize13 header-cart-button1 text-color-444"
          >
            <label for="cart-btn">
              سبدخرید
              <svg
                width="11"
                height="11"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 19L8.5 12L15.5 5"
                  stroke="#a5a5a5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </label>
            <button
              id="cart-btn"
              class="header-cart-button  bg-color-ea rounded-circle"
              @click="cartModalOpen()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                  fill="#F87C56"
                />
              </svg>
              <span class="bg-color-themeBlue text-white rounded-circle">{{
                $store.state.header.cart.length
              }}</span>
            </button>
            <CartModal :closeCartModal="cartModalOpen"></CartModal>
          </div>
        </div>
        <LogoutModal></LogoutModal>
        <LoginModal></LoginModal>
      </section>
      <DesktopMenu class="container"></DesktopMenu>
    </div>
  </header>
</template>
<script>
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.css";
import Dropdown from "../component/dropdown";
import CartModal from "../component/cart/cartModal";
import LoginModal from "../component/header/LoginModal";
import Search from "../component/header/Search";
import DesktopMenu from "../component/header/DesktopMenu";
import MobileMenu from "../component/header/MobileMenu";
import MobileSearch from "../component/header/MobileSearch";
import LogoutModal from "../component/header/LogoutModal";
import UserIcon from "../component/svgs/UserIcon";
import LogoutIcon from "../component/svgs/LogoutIcon";

export default {
  components: {
    LogoutIcon,
    UserIcon,
    LogoutModal,
    DesktopMenu,
    Search,
    Dropdown,
    CartModal,
    LoginModal,
    MobileMenu,
    MobileSearch,
  },
  data() {
    return {
      sendingRequest: false,
      topBannerStatus: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters["header/userInfo"];
    },
    bannerActive() {
      if (this.$store.state.settings) {
        let bannerStatus =
          this.$store.state.settings.filter(
            (item) => item.key == "header_alert_active"
          )[0].value == 1
            ? true
            : false;
        return bannerStatus;
      } else {
        return false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.outsideClicked);
  },
  mounted() {
    // this.initHeaderSlider()

    window.addEventListener("click", this.outsideClicked);
    if (
      !this.$route.fullPath.includes("/admin") &&
      !this.$route.fullPath.includes("/panel") &&
      this.$route.fullPath.valueOf() !== "/"
    ) {
      this.$store.commit("showLoading");
      this.$axios
        .get("/api/all/settings")
        .then((response) => {
          const data = response.data.data;
          this.$store.commit("header/setMenu", data.menus);
          this.$store.commit(
            "header/setUser",
            data["check-user"].data == null ? false : data["check-user"].data
          );
          this.$store.commit(
            "header/setCart",
            data["cart-items"].data.cartItems
          );
          this.$store.commit("setSettings", data.settings);
          this.$store.commit("header/setCategories", data.categories);
          this.$store.commit("header/setBlogCategories", data.blog_categories);
          this.$store.commit("header/setPopularBlogs", data.popular_blogs);
          this.$store.commit("header/setMinMax", data.min_max);
          this.$store.commit("header/setBanners", data.banners);
          this.$store.commit("header/setBrands", data.brands);
        })
        .catch(function(response) {
          //handle error
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    }
  },
  methods: {
    closeTopBanner() {
      this.$root.setCookie(`banner = close`);
      this.topBannerStatus = true;
    },
    outsideClicked(e) {
      if (this.$store.state.header.user) {
        if (!this.$root.hasClass(e.target, "header-cart-button1")) {
          const productCart = document.getElementsByClassName("cart-modal");
          productCart[1].classList.remove("scaleY1");
        }
      }
      if (!this.$root.hasClass(e.target, "product-cat-btn")) {
        let productCat = document.getElementsByClassName(
          "dropdown-menu-click"
        )[0];
        productCat.classList.remove("scaleY1");
      }
      if (
        !this.$root.hasClass(e.target, "resp-menu-btn") &&
        !this.$root.hasClass(e.target, "side-menu")
      ) {
        let hasClassClose = document
          .getElementById("all-categories")
          .classList.contains("all-categories-close");
        let catSide = document.getElementsByClassName("all-categories-side")[0];
        if (hasClassClose) {
          catSide.style.right = "-300px";
        }
        document
          .getElementById("all-categories")
          .classList.remove("all-categories-close");
        // console.log('dd');
      }
    },
    allCategoriesClose() {
      document
        .getElementById("all-categories")
        .classList.toggle("all-categories-close");
      let hasClassClose = document
        .getElementById("all-categories")
        .classList.contains("all-categories-close");
      let catSide = document.getElementsByClassName("all-categories-side")[0];
      // let wrapper = document.getElementsByClassName('wrapper')[0];
      if (hasClassClose) {
        catSide.style.right = "0px";
        // if(window.screen.width >= 1200){
        // wrapper.style.paddingRight="250px";
        // }
      } else {
        // if(window.screen.width >= 1200){
        //     wrapper.style.paddingRight="0";
        // }
        catSide.style.right = "-300px";
      }
    },
    dropdownMenu() {
      let productCat = document.getElementsByClassName(
        "dropdown-menu-click"
      )[0];
      productCat.classList.toggle("scaleY1");
    },
    cartModalOpen() {
      const productCat = document.getElementsByClassName("cart-modal");
      productCat[1].classList.toggle("scaleY1");
    },
    cartModalOpenMobile() {
      const productCat = document.getElementsByClassName("cart-modal");
      productCat[0].classList.toggle("scaleY1");
    },
    initHeaderSlider(kill = false) {
      this.initSwiper("headerSlider", kill, () => {
        new Swiper(".top-slider .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 4000,
            disableOnInteraction: true,
          },
          pagination: {
            el: ".top-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".top-slider .swiper-button-next",
            prevEl: ".top-slider .swiper-button-prev",
          },
        });
      });
    },
    profileClicked() {
      this.$("#loginModal").modal("show");
    },
  },
};
</script>
<style scoped>
.light-color {
  color: var(--color-999);
}

.close {
  position: absolute;
  left: 25px;
}

.mobile-menu {
  min-height: 43px;
}

.profile-container:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 7px solid white;
}

.profile-container {
  background: white;
  border-radius: 10px;
  height: fit-content;
  min-width: 200px;
  box-shadow: #f4f4f4 0 0 10px 1px;
  text-align: right;
  color: var(--color-666);
}

.profile-container li {
  cursor: pointer;
  transition: 200ms all;
  list-style: none;
  padding: 10px 16px;
  border-bottom: solid 1px #f8f8f8;
}

.profile-container li:hover {
  background: #d0d0d0;
}

@media screen and (max-width: 768px) {
  .profile-container:before {
    left: 40%;
  }
}

.mobile-buttons {
  flex: 1;
  justify-content: flex-end;
  gap: 11px;
}

@media screen and (max-width: 1200px) {
  .top-header-R {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .top-header {
    padding-bottom: 0 !important;
  }
}
</style>
<style>
#logout-modal___BV_modal_body_ {
  padding: 0;
}

#logout-modal___BV_modal_header_ .close {
  left: 0px;
  position: absolute;
}

#logout-modal___BV_modal_header_ h5 {
  font-size: 0.8rem;
}

.close-top-banner {
  position: absolute;
  top: 50%;
  left: 20px;
  background-color: #fff;
  border-radius: 50px;
  z-index: 1000;
  transform: translateY(-50%);
}
@media screen and (max-width: 578px) {
  .close-top-banner {
    left: 10px;
  }
}
.headerContinaer {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}
a#logoContainer img {
  width: 130px;
}
a#logoContainer {
  position: absolute;
  right: 0;
  top: 0;
}
.headerContinaer > section {
  max-width: calc(100% - 260px) !important;
  margin: auto;
}
@media screen and (max-width: 1199px) {
  a#logoContainer img {
    width: 80px;
  }
}
@media screen and (max-width: 1199px) {
  .headerContinaer > section {
    max-width: 99% !important;
    padding-right: 80px;
  }
}
button#all-categories svg path {
  stroke: var(--color-themeBlue);
}
</style>
