<template>
  <article v-if="value" class="d-flex justify-content-between border-bottom py-2">
    <div class="d-flex ml-2">
      <div class="cart-modal-img border-radius7 overflow-hidden ml-2">
        <router-link tag="img" :to="{name:'product.detail' , params:{id: value.variety.product.id , slug: value.variety.product.slug}}"
                     :src="value.variety.image" class="w-100 h-100 cursor-pointer">
        </router-link>
      </div>
      <div class="text-right">
        <router-link tag="h5" :to="{name:'product.detail' , params:{id: value.variety.product.id , slug: value.variety.product.slug}}"
          class="fontsize14 weight-bold text-color-444 mb-2 cursor-pointer">
          {{ value.variety.fullTitle.substring(0,60) }}
          {{ value.variety.fullTitle.length > 60 ? '...' : '' }}
        </router-link> 
        <CartColor :value="value" class="mb-2"></CartColor>

      </div>
    </div>
    <div class="d-flex flex-column justify-content-between">
      <CartDeleteButton class="align-self-end p-1 " :value="value"></CartDeleteButton>
      <div class="text-right d-flex">
        <span>{{ value.amount | price }}</span>
        <span class="fontsize12 text-color-999 mr-1">تومان</span>
      </div>
    </div>

  </article>

</template>

<script>
import CartDeleteButton from './cartDeleteButton'
import CartColor from './cartColor'

export default {
  name: "cartItemModal",
  components: {CartColor, CartDeleteButton},
  props: {
    value: Object
  }
}
</script>

<style scoped>

</style>