<template>
  <div id="popup-modal" class="popup-modal address-modal modal fade" tabindex="-1"
       aria-labelledby="popup-modal" aria-hidden="true">
    <div @click="close" class="close">
      <img :src="require('../../../assets/images/close.png')" alt="Close">
    </div>
    <div class="modal-dialog">
      <div class="modal-content" style="background: transparent;border:none">
        <div class="modal-body">
          <a @click="close" target="_blank" :href="link"><img :src="banner"></a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Popup",
  watch: {
    '$store.state.settings': {
      handler() {
        if (!this.settings || this.$root.getCookie(`popup1`)) {
          return
        }
        let settings = this.settings;
        this.$nextTick(() => {
          if (window.innerWidth < 768 && settings.show_popup_mobile) {
            this.$root.setCookie(`popup1 = close`);
            this.$('#popup-modal').modal('show')
          } else if (window.innerWidth > 768 && settings.show_popup_desktop) {
            this.$root.setCookie(`popup1 = close`);
            this.$('#popup-modal').modal('show')
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$('#popup-modal').modal('hide')
    }
  },
  computed: {
    link() {
      if (!this.settings) {
        return ''
      }
      return window.innerWidth < 768 ? this.settings.popup_mobile_link : this.settings.popup_desktop_link
    },
    banner() {
      if (! this.settings) return null;
      return window.innerWidth < 768 ? this.settings.popup_mobile : this.settings.popup_desktop
    },
    settings() {
      if (!this.$store.state.settings) {
        return
      }
      let settings = {}
      this.$store.state.settings.forEach(item => {
        settings[item.key] = item.value
      })
      return settings
    }
  },
}
</script>

<style scoped>
.close {
  background: white;width:fit-content;border-radius: 50%;margin-right: 3px;margin-top: 3px;
  transition: 100ms all;
  cursor: pointer;
}
.close:hover {
  background: #a7a9c2;
}

.modal-body a {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

a img {
  max-width: 100%;
  max-height: unset;
}
</style>
