<template>
  <button @click="goToCompare" v-if="$store.state.compares.length" @mouseenter="$emit('entered')" @mouseleave="$emit('left')"
     class="compare-btn js-start-compare-btn">
    <span>مقایسه</span>
    <span class="compare-btn-count">
      <span class="js-compare-count">{{ $store.state.compares.length }}</span>
      کالا</span>
  </button>
</template>

<script>
export default {
  name: "Compare",
  methods:{
    goToCompare() {
      if (!this.$store.state.compares.length) return;
      this.$router.push({
        path: '/compare', query: {
          compares: this.$store.state.compares.map(i => i.id).join(',')
        }
      })
    }
  }

}
</script>

<style scoped>
.compare-btn {
  position: fixed;
  bottom: 84px;
  left: 30px;
  background-color: var(--color-themeBlue);
  border: 1px solid var(--color-themeBlue-l);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px 10px 10px;
  width: 145px;
  height: 40px;
  cursor: pointer;
  z-index: 3;
}

.compare-btn-count {
  padding: 1px 5px;
  background-color: rgba(0, 0, 0, .15);
  border-radius: 3px;
  z-index: 1;
}
</style>
