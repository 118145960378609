<template>
  <div>
    <myHeader />
    <transition :name="$root.$data.transitionName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
    <Compare @entered="showCompareContent = true" />
    <transition name="fade">
      <CompareContent
        @left="showCompareContent = false"
        v-if="
          !mediaQueries.mobileSize &&
            Boolean($store.state.compares.length) &&
            showCompareContent
        "
      />
    </transition>
    <Popup />
    <myFooter />
  </div>
</template>
<script>
import myHeader from "./shared/header.vue";
import myFooter from "./shared/footer.vue";
import Compare from "./shared/Compare";
import CompareContent from "./shared/CompareContent";
import Popup from "./shared/Popup";

export default {
  components: {
    Popup,
    CompareContent,
    myHeader,
    myFooter,
    Compare,
  },
  inject: ["mediaQueries"],
  data() {
    return {
      showCompareContent: false,
    };
  },
  metaInfo() {
    return {
      meta: [
        this.$route.name != "product.detail"
          ? {
              name: "description",
              content: "فروشگاه اینترنتی آلبا  مدیا - فروش لوازم آرایشی",
            }
          : "",
        this.$route.name != "product.detail"
          ? { property: "og:title", content: "فروشگاه اینترنتی آلبا  مدیا" }
          : "",
        { property: "title", content: "فروشگاه اینترنتی آلبا  " },
        { property: "og:site_name", content: "alba market" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>
<style>
.tooltip-inner {
  background-color: #000000db;
  font-size: 11px;
}
</style>
