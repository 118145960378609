<template>
  <section
    id="main-nav-container"
    class="main-nav bg-color-fa d-flex justify-content-between"
  >
    <div class="d-flex">
      <div
        class="product-cat-btn bg-color-themeBlue p-3 fontsize13 position-relative"
      >
        <button class="bg-white  py-1 px-2 d-inline-block">
          <img src="../../../../assets/images/menu-b.png" alt="" />
        </button>
        <span class="px-2 text-white cursor-pointer ">دسته بندی محصولات</span>
        <ul
          class="dropdown-menu-click dropdown-menu-hover  list-unstyled text-center bg-white "
          style="z-index: 100;"
        >
          <li
            v-for="category in $store.state.header.categories"
            :key="category.id"
            class="nav-item"
            @mouseenter="makeGrid(category)"
          >
            <router-link
              :to="'/products/category/' + category.id + '/' + category.slug"
              class="nav-link"
            >
              {{ category.name }}</router-link
            >
            <transition name="slide">
              <div
                v-if="activeCategory && activeCategory.id == category.id"
                class="container-categories px-3 pb-4 pt-3"
                :style="'width:' + (128 * categoryGrid.length + 35) + 'px'"
              >
                <div
                  class="new-column"
                  v-for="(childCat, index) in categoryGrid"
                  :key="'cat' + index"
                  style="width:128px"
                >
                  <span
                    v-for="(child, index) in childCat"
                    :key="'child' + index"
                    class="d-block text-right mb-1 "
                    :class="child.isParent ? '' : ' pr-2'"
                  >
                    <router-link
                      :to="
                        '/products/category/' +
                          child.item.id +
                          '/' +
                          child.item.slug
                      "
                      class="text-color-666"
                      :class="
                        child.isParent ? 'isParent weight-bold fontsize14' : ''
                      "
                    >
                      {{ child.item.name }}
                    </router-link>
                  </span>
                </div>
              </div>
            </transition>
          </li>
        </ul>
      </div>
      <nav class="main-menu navbar-expand fontsize14">
        <ul class="navbar-nav h-100 mr-3" style="padding-top: 12px;">
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link text-color-themeBlue weight-bold"
              >صفحه اصلی</router-link
            >
          </li>
          <template
            v-if="$store.state.header.menu && $store.state.header.menu[0]"
          >
            <li
              class="nav-item d-flex flex-column"
              :class="{ 'has-children': menu.children.length }"
              v-for="menu in $store.state.header.menu[0].items"
              :key="menu.id"
            >
              <router-link
                v-if="menu.route"
                class="pb-0"
                :to="menu.route ? menu.route : ''"
                :target="menu.target"
                :class="
                  menu.children.length
                    ? 'nav-link d-inline-block font-weight-bold'
                    : 'nav-link font-weight-bold'
                "
              >
                {{ menu.title }}

                <span v-if="menu.children.length">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 8.5L12 15.5L5 8.5"
                      stroke="#666"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </router-link>

              <a
                v-else
                class="pb-0"
                :class="
                  menu.children.length
                    ? 'nav-link d-inline-block font-weight-bold'
                    : 'nav-link font-weight-bold'
                "
                :href="menu.link ? menu.link : ''"
                :target="menu.target"
              >
                {{ menu.title }}
                <span v-if="menu.children.length">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 8.5L12 15.5L5 8.5"
                      stroke="#666"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </a>
              <!-- <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="menu-svg mx-auto"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z"
                  fill="#7570b3" />
              </svg> -->
              <ul
                v-if="menu.children.length"
                class="dropdown-menu-hover  list-unstyled text-center bg-white"
              >
                <li
                  v-for="menuChild in menu.children"
                  :key="menuChild.id"
                  class="nav-item menu-child"
                  :class="{ 'has-sub-menu': menuChild.children != '' }"
                >
                  <router-link
                    class="nav-link"
                    v-if="menuChild.route"
                    :to="menuChild.route ? menuChild.route : ''"
                    :target="menuChild.target"
                  >
                    {{ menuChild.title }}
                    <span v-if="menuChild.children != ''" class="sub-menu-icon">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mx-2 mt-1"
                      >
                        <path
                          d="M15.5 19L8.5 12L15.5 5"
                          stroke="#a5a5a5"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </span>
                  </router-link>
                  <a
                    v-else
                    class="nav-link"
                    :href="menuChild.link ? menuChild.link : ''"
                    :target="menuChild.target"
                    >{{ menuChild.title }}
                    <span v-if="menuChild.children != ''">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mx-2 mt-1"
                      >
                        <path
                          d="M15.5 19L8.5 12L15.5 5"
                          stroke="#a5a5a5"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </span>
                  </a>
                  <ul v-if="menuChild.children != ''" class="sub-menu">
                    <li
                      v-for="subMenu in menuChild.children"
                      :key="'sub-menu' + subMenu.id"
                      class="sub-menu-item"
                    >
                      <router-link
                        class="nav-link"
                        v-if="subMenu.route"
                        :to="subMenu.route ? subMenu.route : ''"
                        :target="subMenu.target"
                      >
                        {{ subMenu.title }}
                      </router-link>
                      <a
                        v-else
                        class="nav-link"
                        :href="subMenu.link ? subMenu.link : ''"
                        :target="subMenu.target"
                        >{{ subMenu.title }}</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/about-us" class="nav-link">
                درباره ما</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">
                تماس با ما</router-link
              >
            </li>
          </template>
          <div class="menu-skeleton-container" v-else>
            <b-skeleton
              class="menu-skeleton"
              v-for="i in 5"
              :key="i"
            ></b-skeleton>
          </div>
        </ul>
      </nav>
    </div>
    <section id="call-us-ml" class="call-us d-flex  mt-3">
      <p class="fontsize12 light-color mb-0" style="padding-top:1px">
        پلی برای شنیدن صدای شما
      </p>
      <svg
        class=" mx-1"
        style="margin-top: 5px;"
        width="11"
        height="11"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <a
        :href="
          `tel:+${
            $store.state.settings
              ? $store.state.settings.filter(
                  (item) => item.key == 'telephone'
                )[0].value
              : ''
          }`
        "
        class="ml-1 text-color-444"
      >
        {{
          $store.state.settings
            ? $store.state.settings.filter((item) => item.key == "telephone")[0]
                .value
            : ""
        }}
      </a>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
          fill="#a5a5a5"
        />
      </svg>
    </section>
  </section>
</template>

<script>
export default {
  name: "DesktopMenu",
  props: {
    // dropdownMenu: Function,
  },
  data() {
    return {
      categoryGrid: [],
      categoriesGrid: [],
      activeCategory: null,
    };
  },
  mounted() {
    this.setContainerWidth();
  },
  methods: {
    setContainerWidth() {
      let topHeader = window.getComputedStyle(
        document.getElementById("top-header")
      );
      let marginRight = topHeader.getPropertyValue("margin-right");
      let marginLeft = topHeader.getPropertyValue("margin-left");
      marginRight = +marginRight.split("px")[0] + 15;
      let mainNavWidth = window.innerWidth - marginRight;
      document.getElementById("main-nav-container").style.marginRight =
        marginRight + "px";
      document.getElementById("call-us-ml").style.marginLeft = marginLeft;
    },
    makeGrid(category) {
      this.activeCategory = category;
      let find = this.categoriesGrid.find(
        (item) => item.category_id == category.id
      );
      if (find) {
        this.categoryGrid = find.items;
        return;
      } else {
        let arr = [];
        let currentRow = -1;
        const max = 22;
        category.children.forEach((firstLayer) => {
          let totalSegments = this.getTotalSegmants(firstLayer);
          if (!arr[currentRow]) {
            arr.push([]);
          }
          // let leftSegments = max - arr[currentRow].length;
          //
          // if (leftSegments >= totalSegments) {
          //   arr[currentRow].push({
          //     isParent: true,
          //     item: firstLayer,
          //   });
          //   firstLayer.children.forEach((item) => {
          //     arr[currentRow].push({
          //       isParent: false,
          //       item,
          //     });
          //   });
          // } else
          {
            currentRow = currentRow + 1;
            arr.push([]);
            arr[currentRow].push({
              isParent: true,
              item: firstLayer,
            });
            let c = 0;
            firstLayer.children.forEach((item, index) => {
              if (c++ > 10) {
                currentRow++;
                c = 0;
              }
              arr[currentRow].push({
                isParent: false,
                item,
              });
            });
          }
        });
        arr = arr.filter((a) => a.length);
        this.categoryGrid = arr;
        this.categoriesGrid.push({ category_id: category.id, items: arr });
      }
    },
    getTotalSegmants(category) {
      return 1 + category.children.length;
    },
    pushCategories() {},
  },
};
</script>

<style scoped>
.menu-skeleton-container {
  /*width: 700px;*/
  display: flex;
  padding-top: 9px;
  margin-right: 12px;
}

.menu-skeleton {
  width: 78px;
  margin-left: 22px;
}

.sub-menu-icon {
  position: absolute;
  left: -10px;
}

.dropdown-menu-hover .sub-menu {
  min-width: 170px;
  position: absolute;
  right: 100%;
  top: 0;
  transform: scalex(0.9) translateX(10px);
  transition: all 0.3s;
  transform-origin: top;
  border-radius: 5px 0 5px 5px;
  z-index: 200;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0px 0px 5px -3px;
  background-color: white;
}

.dropdown-menu-hover .has-sub-menu:hover .sub-menu {
  transform: scaleX(1) translateX(0);
  opacity: 1;
}

.sub-menu-item {
  position: relative;
  padding: 2px 10px;
  font-style: 12px;
}

.sub-menu-item::after {
  border-bottom: 1px solid var(--color-ea) !important;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-f3f2ff);
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
}

.main-menu ul .sub-menu-item a {
  transition: unset;
}
.product-cat-btn .nav-item {
  position: unset !important;
}

.dropdown-menu-hover .container-categories {
  position: absolute;
  /* opacity: 0; */
  transition: all 0.2s;
  right: 100%;
  top: 0;
  background-color: #fff;
  direction: rtl;
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  /* grid-template-rows: repeat(20, 1fr); */
  gap: 0px 0px;
  grid-auto-flow: column;
  box-shadow: -4px 0 15px rgba(56, 56, 56, 0.11);
  height: auto;
  min-height: 100%;
  padding: 10px 10px;
  border-radius: 7px 0 7px 7px;
}
.new-column {
  grid-row: 1/1;
}
.dropdown-menu-hover .container-categories span:hover a {
  color: var(--color-themeBlue) !important;
  font-weight: 700;
}
</style>
<style>
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(5px, 0);
  opacity: 0;
}
.slide-leave-to {
  opacity: 0;
  /* transform: translate(-5px, 0); */
}
.isParent::before {
  content: ".";
  font-size: 31px;
  color: var(--color-themeBlue);
}
</style>
