<template>
  <div class="searchModal modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <div class="modal-body px-sm-5 px-3 pb-sm-5 pb-3">
          <form @submit.prevent="proSearch" class="fontsize12">
            <select v-model="c" name="" id="" class="w-100 text-color-themeBlue font-weight-bold p-2 mb-3 ">
              <option value="0" selected disabled>جستجوی پیشرفته</option>
              <template v-for="(category, index) in $store.state.header.categories">
                <option class="search-inside-click" v-if="category"
                        :key="index" :value="category.id">{{ category.name }}</option>
              </template>

            </select>
            <div class="d-flex justify-content-between  p-2 ">
              <input v-model="q" type="search" class="w-75" placeholder="جستجو کنید...">

              <hr/>
              <button type="submit" class="search-button pr-2">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11.7666" cy="11.7666" r="8.98856" stroke="#7570b399"
                          stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.0183 18.4851L21.5423 22" stroke="#7570b399"
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>

          </form>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MobileSearch",
  data() {
    return {
      q: '',
      c: 0
    }
  },
  methods: {
    proSearch() {
      this.$('#searchModal').modal('hide')
      if (this.c != 0) {
        this.$router.push('/products/category/' + this.c + '?title=' + this.q);
      } else {
        this.$router.push('/products?title=' + this.q);
      }
    }
  }
}
</script>

<style scoped>

</style>
